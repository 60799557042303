import axios from "axios";

export const instanceIP = axios.create({
    baseURL: 'https://www.cloudflare.com/'
});

export const instanceaATS = axios.create({
    //baseURL: 'https://ats.sysmatch.com/ats/webservices/atsapplication.php?ws_ats=3&company_id=3&start=1&end=250'
    baseURL: 'https://www.hereandnow.agency/api/get-jobs/sysmatch'
});

export const instanceaAWS = axios.create({
    //baseURL: 'https://oisljbz8m3.execute-api.eu-west-1.amazonaws.com/test',
    baseURL: 'https://oisljbz8m3.execute-api.eu-west-1.amazonaws.com/prod',
    headers: {
        'x-api-key': 'ev4nLoS6Gz6ev67rRj0BBaEWzriDCJQG4sbmpZnS',
    }
});

export const instanceMlog = axios.create({
    baseURL: 'https://mlogapi.sysmatch.com',
    headers: {
        "content-type": "application/json"
    },
});